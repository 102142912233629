<template>
  <div class="Portfolio">
    <!-- 分类操作栏 -->
    <div class="sort">
      <div class="sort_model">
        <div class="sort_item" v-for="(item, index) in sortList" :key="index">
          <div class="sort_item_title">{{ item.title }}：</div>
          <div class="sort_items">
            <div
              class="sort_itemSubclass"
              v-for="(list, listIndex) in item.children"
              :key="listIndex">
              <div
                :style="list.state ? 'background-color: #F1DC64;' : ''"
                @click="passID(list.id, index, listIndex)">
                {{ list.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 排序筛选 -->
    <div class="filter">
      <div class="filter_title">排序</div>
      <div class="filter_list">
        <!-- 最新 -->
        <div
          :style="listParameter.new ? 'color: green;' : ''"
          class="filter_list_item"
          @click="newestData"
        >
          最新上传
        </div>
        <!-- 最热 -->
        <div
          :style="listParameter.hot ? 'color: green;' : ''"
          class="filter_list_item"
          @click="hottest"
        >
          最热下载
        </div>
      </div>
    </div>
    <!-- 列表内容 -->
    <div class="card_list">
      <!-- 列表内容项 -->
      <div
        class="card_list_item"
        v-for="(item, index) in list"
        :key="index"
        @click="openDetails(item.id)"
      >
        <!-- 卡片图片 -->
        <div class="card_list_itemImg">
          <img :src="item.cover" />
        </div>
        <!-- 卡片内容 -->
        <div class="card_list_itemContent">
          <div class="card_list_itemContentText">{{ item.title }}</div>
          <!-- 卡片标题 -->
          <!-- <el-popover placement="top-start" width="200" trigger="hover" :open-delay="500"> -->
          <!-- <user-model
              :
          ></user-model>-->
          <!-- 发布人 -->
          <div class="card_list_itemContentUser" slot="reference">
            <div>
              <img :src="item.picture" />
            </div>
            <div>{{ item.username }}</div>
          </div>
          <!-- </el-popover> -->
        </div>
        <!-- 卡片数据 -->
        <div class="card_list_itemData">
          <div>
            <div>
              <i class="el-icon-view"></i>
            </div>
            <div>{{ item.visit_num }}</div>
          </div>
          <div>
            <div>
              <i class="iconfont icondianzan"></i>
            </div>
            <div>{{ item.like_num }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="card_pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        :current-page="listParameter.page"
        @current-change="handleCurrentChange"
        :page-size="20"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import userModel from "@/components/userModel.vue";

export default {
  props: ["searchValue"],
  data() {
    return {
      // 分类
      sortList: [],
      // 方案数据
      list: [],
      // 方案数据中数量
      count: 0,
      // 发送的参数
      listParameter: {
        // 当前页
        page: 1,
        title: "",
        username: "",
        cat_id: "",
        hot: "",
        new: "",
      },
      cancel: null,
      // 被选中id合集
      sortId: [],
    };
  },
  watch: {
    // 在watch中监听data的数据，监听的数据不要使用箭头函数，不然会报自定义的错误。
    searchValue(value) {
      new Promise((resolve, reject) => {
        if (value.id == 1) {
          this.listParameter.title = value.content;
        } else {
          this.listParameter.username = value.content;
        }
        resolve("查询数据");
      }).then((res) => {
        this.intList();
        this.listParameter = {};
      });
    },
  },
  created() {
    this.intList();
    this.intSort();
  },
  components: {
    userModel,
  },
  methods: {
    // 初始化分类
    intSort() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          list.map((item) => {
            item.children.map((list) => {
              list.state = false;
            });
          });
          this.sortList = list;
        }
      });
    },
    // 初始化列表
    async intList() {
      const CancelToken = this.$axios.CancelToken;
      if (this.cancel) {
        this.cancel.cancel("取消上次请求");
      }
      this.cancel = CancelToken.source();
      let listParameter = this.listParameter;
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        cancelToken: this.cancel.token,
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: listParameter.page,
          title: listParameter.title,
          username: listParameter.username,
          cat_id: listParameter.cat_id,
          hot: listParameter.hot,
          new: listParameter.new,
        },
      }).then((res) => {
        let list = res.data.data;
        this.list = list.res;
        this.count = list.count;
      });
    },
    // 打开详情
    openDetails(e) {
      this.$router.push({
        path: "/HomeDetails",
        query: {
          id: e,
        },
      });
    },
    // 最新上传
    newestData() {
      this.listParameter.new = !this.listParameter.new;
      this.intList();
    },
    // 最热下载
    hottest() {
      this.listParameter.hot = !this.listParameter.hot;
      this.intList();
    },
    // 分类id保存
    passID(id, index, listIndex) {
      if (this.sortId.length == 0) {
        this.sortId.push(id);
        this.sortList[index].children[listIndex].state = true;
      } else {
        let state = this.sortId.findIndex((item) => item == id);
        if (state == -1) {
          this.sortId.push(id);
          this.sortList[index].children[listIndex].state = true;
        } else {
          this.sortId.splice(state, 1);
          this.sortList[index].children[listIndex].state = false;
        }
      }
      this.handleSort();
    },
    // 获取相应分类的数据
    handleSort() {
      let id = "";
      this.sortId.map((item, index) => {
        if (this.sortId.length == index + 1) {
          id += item;
        } else {
          id += item + ",";
        }
      });
      this.listParameter.cat_id = id;
      this.intList();
    },
    // 当前页发生变化
    handleCurrentChange(val) {
      this.listParameter.page = val;
      this.intList();
    },
  },
};
</script>
<style scoped>
.Portfolio {
  width: 80%;
  margin: 10px auto;
}
@media screen and (max-width: 1500px) {
  .Portfolio {
    width: 1200px;
  }
}
/* 分类操作栏 */
.sort {
  margin: 30px 0;
}
.sort_model {
  overflow: hidden;
  transition: height 0.5s;
}
.sort_item {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
}
.sort_item_title{
  margin: 10px 0;
  min-width: 5%;
  cursor: default;
}
.sort_items{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sort_itemSubclass {
  font-weight: normal;
  cursor: pointer;
}
.sort_itemSubclass > div {
  text-align: center;
  border-radius: 5px;
  margin: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
}

/* 排序筛选 */
.filter {
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-starts;
}
.filter_title {
  padding: 0 40px;
  border-right: 1px solid #666;
}
.filter_list {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* 卡片内容列表 */
.card_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card_list_item {
  width: 21%;
  font-size: 14px;
  border-radius: 10px;
  margin: 1% 2%;
  background-color: #fff;
  transition: box-shadow 0.5s;
}
.card_list_item:hover {
  box-shadow: 7px 7px 7px #e3e3e3, -7px -7px 7px #f4f4f4;
}
.card_list_itemImg {
  width: 100%;
  height: 20vh;
}
.card_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 10px;
}
.card_list_itemImg > img:hover {
  transform: scale(1.5);
}
.card_list_itemContent {
  cursor: pointer;
  box-sizing: border-box;
  margin: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.card_list_itemContentText {
  box-sizing: border-box;
  padding: 20px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_list_itemContentUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card_list_itemContentUser > div {
  box-sizing: border-box;
  margin: 20px 10px;
}
.card_list_itemContentUser img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.card_list_itemData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_list_itemData > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_list_itemData {
  padding: 20px;
  color: #666;
  box-sizing: border-box;
}
.card_list_itemData i {
  font-size: 20px;
  margin: 0 10px;
  box-sizing: border-box;
}
/* 分页 */
.card_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card_pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #fdd30f;
}
.card_pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  color: #fdd30f;
}
</style>